<template>
  <base-page class="AboutPage">
    <template slot="header">
      <page-header
        :showNavigationBar="!isMobile"
        :useCateringContactNumber="false">
        <banner-content
          :title="$t('about.bannerHeader')"
          :byline="$t('about.bannerText')"/>
      </page-header>
    </template>
    <div class="Container">
      <div class="Section BeliefSection">
        <base-header :title="$t('about.beliefHeader')"/>
        <grid-row :keepColumnWidth="true">
          <grid-column
            v-for="(keyPoint, i) in keyPoints"
            :key="`key_point_${i}`"
            :width="100/keyPoints.length">
            <div class="KeyPoint">
              <h4 v-html="$t(keyPoint.text, { displayClass: 'Display' })"/>
              <img :src="keyPoint.image">
              <h4 v-html="$t(keyPoint.text, { displayClass: 'Display' })"/>
            </div>
          </grid-column>
        </grid-row>
      </div>
    </div>
    <div class="Container FullWidth FeaturedSectionContainer">
      <div class="Section FeaturedSection">
        <h3>
          {{ $t('about.featuredHeader') }}
        </h3>
        <div class="ImageList">
          <div
            v-for="image in featured"
            :key="image.src"
            class="ImageListImageContainer">
            <img
              :src="image.src"
              :class="`ImageListImage ${image.class}`">
          </div>
        </div>
      </div>
    </div>
    <div class="Container FullWidth PhilosophySectionContainer">
      <div
        id="people"
        class="Section PhilosophySection">
        <div class="ImageContainer">
          <img :src="require('@/assets/images/about/people-first.jpg')">
        </div>
        <base-header
          :title="$t('about.philosophyHeader')"
          :byline="$t('about.philosophyText', { displayClass: 'Display' })"/>
      </div>
    </div>
    <div class="Container FullWidth InvestorsSectionContainer">
      <div class="Section InvestorsSection">
        <base-header
          :title="$t('about.investorsHeader')"
          :byline="$t('about.investorsText')"/>
        <div class="ImageList">
          <div
            v-for="image in investors"
            :key="image.src"
            class="ImageListImageContainer">
            <img
              :src="image.src"
              :class="`ImageListImage ${image.class}`">
          </div>
        </div>
        <h5>
          {{ $t('about.investorsIndependent') }}
        </h5>
      </div>
    </div>
    <template slot="footer">
      <page-footer v-if="!isMobile"/>
    </template>
  </base-page>
</template>

<script>
import BasePage from '@/components/common/BasePage'
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'
import BannerContent from '@/components/BannerContent'
import BaseHeader from '@/components/common/BaseHeader'
import GridRow from '@/components/common/GridRow'
import GridColumn from '@/components/common/GridColumn'

export default {
  name: 'about-page',
  components: {
    BasePage,
    PageHeader,
    PageFooter,
    BannerContent,
    BaseHeader,
    GridRow,
    GridColumn
  },
  data() {
    return {
      keyPoints: [
        {
          image: require('@/assets/images/about/about-experiment-play-cook-up.jpg'),
          text: 'about.keyPoint1'
        },
        {
          image: require('@/assets/images/about/about-familiar-unexpected.jpg'),
          text: 'about.keyPoint2'
        },
        {
          image: require('@/assets/images/about/best-service.jpg'),
          text: 'about.keyPoint3'
        }
      ],
      featured: [
        {
          src: require('@/assets/images/about/featured-techinasia.png'),
          class: 'logo-techinasia'
        },
        {
          src: require('@/assets/images/about/featured-cnbc.svg'),
          class: 'logo-cnbc'
        },
        {
          src: require('@/assets/images/about/featured-forbes.png'),
          class: 'logo-forbes'
        },
        {
          src: require('@/assets/images/about/featured-the-straits-times.png'),
          class: 'logo-thestraitstimes'
        },
        {
          src: require('@/assets/images/about/featured-8-days.png'),
          class: 'logo-8days'
        },
        {
          src: require('@/assets/images/about/featured-women-s-weekly.png'),
          class: 'logo-womensweekly'
        }
      ],
      investors: [
        {
          src: require('@/assets/images/about/investor-openspace.png'),
          class: 'logo-openspace'
        },
        {
          src: require('@/assets/images/about/investor-500.png'),
          class: 'logo-500'
        },
        {
          src: require('@/assets/images/about/investor-cento.png'),
          class: 'logo-cento'
        },
        {
          src: require('@/assets/images/about/investor-majuven.png'),
          class: 'logo-majuven'
        },
        {
          src: require('@/assets/images/about/investor-foodxervices.png'),
          class: 'logo-foodxervices'
        }
      ]
    }
  },
  computed: {
    isMobile() {
      return window.location.search.indexOf('?m=1') !== -1 || window.location.search.indexOf('&m=1') !== -1
    }
  },
  mounted() {
    window.removeEventListener('scroll', this.updateNavbarClass)
    window.addEventListener('scroll', this.updateNavbarClass)
    this.updateNavbarClass()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateNavbarClass)
  },
  methods: {
    updateNavbarClass() {
      if (document.querySelector('.NavigationBarContainer') && !document.querySelector('.NavigationBar')) {
        return setTimeout(this.updateNavbarClass, 1)
      }

      if (window.scrollY === 0) {
        document.querySelector('.NavigationBarContainer').classList.add('TopOfPage')
        document.querySelector('.NavigationBar').classList.add('TopOfPage', 'White')
      } else {
        document.querySelector('.NavigationBarContainer').classList.remove('TopOfPage')
        document.querySelector('.NavigationBar').classList.remove('TopOfPage', 'White')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.AboutPage {
  background-color: $primary-bg;
  overflow-x: hidden;

  /deep/ .NavigationBarContainer {
    position: fixed;
    z-index: 1000;
    width: 100%;

    &.TopOfPage {
      .TopBarWrap {
        background-color: transparent;
        box-shadow: none;

        .top-bar:not(.expanded) {
          .top-bar-section {
            ul > li > a {
              color: $white;
            }
          }

          .toggle-topbar.menu-icon {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
    .TopBarWrap {
      box-shadow: none;
      transition: background-color 0.2s, box-shadow 0.2s;
    }
  }
  /deep/ .Banner {
    height: 592px;
    background-image: url('~@/assets/images/about/banner-about.jpg');
    background-position: center top;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
    @media #{$medium-only} {
      height: 444px;
    }
    @media #{$small-only} {
      padding-top: 60px;
      height: 260px;
    }
    .CopyContainer {
      .BaseHeader {
        .HeaderTitle {
          font-family: $display-two-font;
          font-size: 3.5rem;
          line-height: 4.25rem;

          @media #{$medium-up} {
            line-height: 3.375rem;
          }
          @media #{$small-only} {
            margin-bottom: $space-xs;
            font-size: 1.75rem;
            line-height: 2.25rem;
          }
        }
      }
    }
  }
  /deep/ .PageBody {
    margin-bottom: 0;
  }
  .FeaturedSectionContainer,
  .InvestorsSectionContainer {
    background-color: #F6F2EC;
  }
  .PhilosophySectionContainer {
    padding-top: $space-m;
    padding-bottom: $space-m;

    .Section {
      margin-top: 0;
      margin-bottom: 0;
    }
    /deep/ .HeaderTitle {
      margin-top: 0;
    }
  }

  .InvestorsSectionContainer {
    padding-top: $space-xl;
    padding-bottom: $space-xl;

    .Section {
      margin-top: 0;
      margin-bottom: 0;
    }
    /deep/ .HeaderTitle {
      margin-top: 0;
    }
  }

  .Section {
    max-width: 60rem;
    margin: $space-xl auto;

    @media #{$small-only} {
      margin-top: $space-l;
      margin-bottom: $space-l;

      /deep/ .HeaderTitle {
        margin-top: 0;
      }
    }
    &.BeliefSection {
      /deep/ .HeaderTitle {
        margin-top: 0;
      }

      @media (max-width: 40em) {
        margin-bottom: 0;

        /deep/ .HeaderTitle {
          font-size: 1.125rem;
          line-height: 1.75rem;
          display: block;
          margin: auto;
          max-width: 220px;
        }
      }
      /deep/ .GridRow {
        margin-left: -$space-xs;
        margin-right: -$space-xs;

        > .GridColumn {
          padding-left: $space-xs;
          padding-right: $space-xs;
        }

        @media #{$small-only} {
          margin-left: -$space-m;
          margin-right: -$space-m;

          > .GridColumn {
            width: 100% !important;
            padding-left: 0;
            padding-right: 0;

            @media (min-width: 481px) {
              &:nth-child(2) {
                h4 {
                  display: inline-block;
                }
                img + h4 {
                  display: none;
                }
              }
            }
          }
        }
      }
      .KeyPoint {
        margin-left: auto;
        margin-right: auto;

        img {
          margin-bottom: $space-m;
        }
        h4 {
          display: none;
          text-align: center;
        }
        img + h4 {
          display: inline-block;
        }
        @media #{$small-only} {
          img, h4 {
            box-sizing: border-box;
            margin-bottom: 0;
            width: 50%;
            vertical-align: middle;
          }
          img {
            display: inline-block;
          }
          h4 {
            padding: 0 $space-m;
          }
        }
        @media (max-width: 480px) {
          img, h4 {
            display: block;
            width: 100%;
          }
          h4 {
            display: none;
            padding-top: $space-l;
            padding-bottom: $space-l;
          }
          img + h4 {
            display: block;
          }
        }
      }
    }
    &.FeaturedSection,
    &.InvestorsSection {
      .ImageList {
        margin-left: -$space-l;
        margin-right: -$space-l;
        text-align: center;

        .ImageListImageContainer {
          display: inline-block;

          .ImageListImage {
            display: inline-block;
            max-width: 100%;
            padding: 0 $space-l;
            vertical-align: middle;
          }
          .logo-techinasia {
            width: 165px;
          }
          .logo-cnbc {
            width: 67px;
          }
          .logo-forbes {
            width: 86px;
          }
          .logo-thestraitstimes {
            width: 231px;
          }
          .logo-8days {
            width: 27px;
          }
          .logo-womensweekly {
            width: 144px;
          }
          .logo-openspace {
            width: 182px;
          }
          .logo-500 {
            width: 52px;
          }
          .logo-cento {
            width: 120px;
          }
          .logo-majuven {
            width: 152px;
          }
          .logo-foodxervices {
            width: 134px;
          }
        }
      }
    }
    &.FeaturedSection {
      margin-top: 0;
      margin-bottom: 0;
      padding: $space-xl 0;

      h3 {
        margin-bottom: $space-m;
        text-align: center;
        font-weight: bold;
      }
    }
    &.PhilosophySection {

      @media #{$small-only} {
        /deep/ .HeaderTitle {
          font-size: 1.125rem;
          line-height: 1.75rem;
          display: block;
          margin: auto;
          max-width: 220px;
        }
      }

      .ImageContainer, .BaseHeader {
        box-sizing: border-box;
        display: inline-block;
        padding-left: $space-s;
        padding-right: $space-s;
        vertical-align: middle;

        @media #{$medium-up} {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      .ImageContainer {
        width: 47%;

        img {
          max-width: 400px;
          margin: 0;
        }
      }
      .BaseHeader {
        width: 53%;
        max-width: 100%;

        /deep/ .HeaderByline {
          margin-bottom: 0;
        }
      }
      @media only screen and (max-width: 800px) {
        .ImageContainer, .BaseHeader {
          width: 50%;
        }
      }
      @media #{$small-only} {
        .ImageContainer, .BaseHeader {
          width: 100%;
        }
        .ImageContainer {
          padding-left: 0;
          padding-right: 0;

          img {
            max-width: 100%;
          }
        }
        .BaseHeader {
          padding: $space-xl 0;
        }
      }
    }
    &.InvestorsSection {
      @media #{$small-only} {
        /deep/ .HeaderTitle {
          font-size: 1.125rem;
          line-height: 1.75rem;
        }
     }

      /deep/ .HeaderByline {
        margin-bottom: $space-l;
      }
      h5 {
        text-align: center;
      }
    }
    .BaseHeader {
      @media #{$large-up} {
        max-width: 55%;
        margin: 0 auto;
      }
      @media #{$medium-only} {
        max-width: 80%;
        margin: 0 auto;
      }
      /deep/ .HeaderTitle {
        margin-bottom: $space-m;
      }
      /deep/ .HeaderByline {
        color: $ink;
        margin-bottom: $space-xl;
      }
    }
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
    /deep/ .Display {
      font-family: $display-font;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}
</style>
