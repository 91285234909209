<template>
  <div class="BannerContent">
    <div
      class="CopyContainer"
      :class="{
        'Light': theme === 'light',
        'Dark': theme === 'dark'
      }">
      <base-header>
        <slot
          slot="title"
          name="title">
          <span v-html="title"/>
        </slot>
        <slot
          slot="byline"
          name="byline">
          <span v-html="byline"/>
        </slot>
      </base-header>
    </div>
  </div>
</template>

<script>
const BaseHeader = () => import('@/components/common/BaseHeader')

export default {
  name: 'banner-content',
  components: {
    BaseHeader
  },
  props: {
    title: {
      type: String,
      required: true
    },
    byline: {
      type: String,
      default: null
    },
    theme: {
      type: String,
      default: 'dark'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base";
@import "~@/assets/css/_shared_variables.sass";

.BannerContent {
  position: relative;
  height: 100%;

  .CopyContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    max-width: 550px;
    transform: translate(-50%, -50%);

    @media #{$small-only} {
      padding-left: $space-m;
      padding-right: $space-m;
      padding-bottom: 0;
    }

    /deep/ .BaseHeader {
      .HeaderTitle,
      .HeaderByline {
        color: $primary-bg;
        text-align: center;
      }
      .HeaderTitle {
        margin-top: 0;
        margin-bottom: $space-xs;
        font-family: $display-font;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
        @extend %display_x_large;
      }
      .HeaderByline {
        margin-bottom: 0;
        @extend %display_light;
        text-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;

        @media #{$small-only} {
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }
    }
    &.Light {
      /deep/ .BaseHeader {
        .HeaderTitle,
        .HeaderByline {
          color: $ink;
        }
        .HeaderTitle {
          text-shadow: none;
        }
        .HeaderByline {
          text-shadow: none;
        }
      }
    }
  }
}
</style>
